import * as d3 from 'd3-color'

export const fixColor = (color)=>{
  const newColor = d3.hsl(color)
  newColor.l = 0.7
  newColor.s = 0.8
  return color//newColor.formatHex()
}
export const sortColor = (a, b)=>{
  //return ('' + a.Color).localeCompare(b.Color);
  // return d3.cubehelix(a.Color).h - d3.cubehelix(b.Color).h
  return ('' + d3.cubehelix(a.Color).formatHsl()).localeCompare(d3.cubehelix(b.Color).formatHsl());

}