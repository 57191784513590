import React, { useRef, useEffect, useState, useLayoutEffect } from 'react'
import * as THREE from 'three';
import { useLoader } from 'react-three-fiber'

import { curves } from '../../../assets/curves.json'
import { greenery } from '../../../assets/greenery.json'
import lawnPath from '../../../assets/noise1.jpg'

import CreatePath from '../createPath'
import CreateShape from '../createShape'


export default () => {

    const [loaded, setLoaded] = useState(false)
    const lines = useRef()
    const shapes = useRef()
    const lawnMap = useLoader(THREE.TextureLoader, lawnPath)

    useEffect(()=>{
      lawnMap.wrapS = THREE.RepeatWrapping
      lawnMap.wrapT = THREE.RepeatWrapping
      lawnMap.repeat.set( 0.12, 0.12 );

    },[lawnMap])
    useLayoutEffect(()=>{
      setLoaded(true)

      if(!loaded){
        setLoaded(true)
        
        const lineMaterial = new THREE.LineBasicMaterial({ color: 'lightgrey', linewidth: 1 });

        curves.forEach((c) => {
          const points = CreatePath(c).getSpacedPoints(300);
          const geometry = new THREE.BufferGeometry().setFromPoints(points);
          const line = new THREE.Line(geometry, lineMaterial);
          lines.current.add(line);
        });

                
        const material = new THREE.MeshPhongMaterial({ color: '#e1f6e2', bumpScale:0.3, bumpMap: lawnMap, side: THREE.BackSide /*color: "#ffc453", bumpMap: lawnMap, emissive: "#222222", bumpScale:0.3, side: THREE.BackSide*/ });

        greenery.forEach((c) => {
          const points = CreateShape(c)//.getSpacedPoints(300);
          const geometry = new THREE.ShapeBufferGeometry(points);
          const line = new THREE.Mesh(geometry, material);
          shapes.current.add(line);
        });

      }
    }, [loaded])
    
    return ( <>
    <object3D name="Shapes" ref={shapes} rotation={[Math.PI / 2,0,0]} transition={[0,100,0]} />
    <object3D name="Lines" ref={lines} rotation={[Math.PI / 2,0,0]}/> 
    </>
    )
}