/* eslint-disable react/display-name */
import React, {  useEffect } from 'react';
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'
import { makeStyles } from '@material-ui/core/styles';
import { Box, Paper, Container } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import * as moment from "moment/moment";
import * as Atoms from 'global/Atoms';
import { useSetRecoilState } from 'recoil'

const useStyles = makeStyles(theme => ({

  icons: {
    alignItems:"center",
    justifyContent:"center",
    display:"flex",
    textAlign: "center",
  },
  dateblock:{
    position: 'fixed',
    //height: '76px',
    bottom: 0,
    paddingTop: 10,
    paddingBottom: 8,
    width: "100%",
    borderTop: "0.05px solid rgba(0,0,0,0.1)",
    borderRadius: 0,
    [theme.breakpoints.up('sm')]: {
      width: '50%',
      right:0,
      maxWidth: 350,
    }
  }
}));

const isToday = (someDate) => {
  const today = new Date()
  return someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
}

 const DateBlock = ({ getDateProp, setDateProp, kind, ...props })=> {
  const classes = useStyles()
  const setDateBlockSize = useSetRecoilState(Atoms.dateBlockAtom)
  const [block, size] = useMeasure({ polyfill: ResizeObserver })
  
  useEffect(()=>{
    if(size.height){
      setDateBlockSize(size)
      //console.log('height', size.height)
    }
  },[size, setDateBlockSize])

  return !kind && (
    <Paper id="DateBlock" className={classes.dateblock} style={{boxSizing:"border-box", zIndex: 5}} ref={block}>
      <Container>
          <Box  className={classes.icons}>
          <IconButton 
            aria-label="previous week"
            onClick={()=>{
              let previous = new Date(getDateProp);
              previous.setDate(getDateProp.getDate() - 7)
              if(previous>(new Date(new Date().getFullYear(), 0, 0))){
                setDateProp(previous)
              }
            }}
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="button" style={{marginLeft: "auto"}}> 

            {isToday(getDateProp)?
              <>Today</>:
              moment(getDateProp).format('MMMM D')
            }      
          </Typography>
          <IconButton
            aria-label="next week"
            style={{ marginLeft: "auto" }}
            onClick={()=>{
              let next = new Date(getDateProp);
              next.setDate(getDateProp.getDate() + 7);
              if(next<(new Date(new Date().getFullYear(), 11, 31))){
                setDateProp(next)
              }
            }}
          >
            <ArrowForward />
          </IconButton>
        </Box>
        </Container>
      </Paper>
  );
}

  export default DateBlock