import React, { useLayoutEffect, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import * as moment from "moment/moment";

const lastDay = new Date((new Date()).getFullYear(), 12, 0)
const firstDay =  new Date((new Date()).getFullYear(), 0, 1)
const span = lastDay - firstDay

const StyledSlider = withStyles({
  root: {
    color: 'rgb(68, 173, 83)',
    height: 1,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    borderRadius: 12,
    fontSize: "24px",
    marginTop: -10,
    marginLeft: -6,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 4,
    borderRadius: 0,
    color: 'rgba(0,0,0,0)',
  },
  rail: {
    height: 4,
    borderRadius: 4,
    backgroundImage: 'linear-gradient(90deg, rgba(68,197,255,0.8) 0%, rgba(51,200,50,1) 25%, rgba(233,216,0,1) 53%, rgba(68,197,255,0.8) 100%);',
    opacity: 1
  },
})(Slider);

export default ({date, setDate})=>{
  const slide = useRef()

  useLayoutEffect(()=>{
    //slide.current.children[3].innerHTML = "🌸"
  },[slide])
  return <StyledSlider 
    style={{position:"absolute", bottom: "1.4rem", width: "calc( 100% - 4rem )", left: "2rem" }}
    valueLabelDisplay="auto" 
    aria-label="Date slider" 
    ref={slide}
    //getAriaValueText={(value)=>{return `${value}°C`;}}
    //ValueLabelComponent={ValueLabelComponent}
    defaultValue={Number((((new Date() - firstDay)/(lastDay - firstDay))*100).toFixed(0))} 
    value={date? Number((((date - firstDay)/(lastDay - firstDay))*100).toFixed(0)):
      Number((((new Date() - firstDay)/(lastDay - firstDay))*100).toFixed(0))
    }
    valueLabelFormat={(value)=>(moment(date?date:new Date()).format('MMM'))}
    onChange={(e,d)=>setDate(new Date(firstDay.getTime() + (span*d)/100))}
  />
}