import React, { useRef, useLayoutEffect, useEffect, useState, Suspense } from 'react'
import * as THREE from 'three';
import * as easings from 'd3-ease'
import { Canvas, useLoader, useThree, useFrame } from 'react-three-fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { Typography, Button, ButtonGroup } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useRecoilState, useSetRecoilState } from 'recoil'
import * as Atoms from 'global/Atoms'
import { HTML, MapControls as OrbitControls } from 'drei'
import { useSpring } from 'react-spring'
import lerp from "lerp"

import { Home } from '@material-ui/icons'
import scenePath from '../../assets/scene.gltf'
import gradientPath from '../../assets/gradient.png';
import Lines from './lines'
import Trees from './trees'
import Flowers from './flowers'

const l = {
  "North End": {x: 830, y: 615, z: -185, tx: 510, ty: 0, tz: -290, zoom:1.8},
  "Dewey Square": {x: 990, y: 600, z: 660, tx: 705, ty: 0, tz: 630, zoom: 1.8},
  "Wharf District": {x: 800, y: 550, z: 415, tx: 850, ty: 0, tz: 120, zoom: 1.25},
  "Chinatown": {x: 480, y: 750, z: 740, tx: 230, ty: 0, tz:  920, zoom: 2.35},
  //default: {x: 920, y: 460, z: 260, tx:590, ty:-100, tz:240, zoom: document.body.offsetWidth?(document.body.offsetWidth/3000):0.18 }
  default: {x: 450, y: 475, z: 530, tx:450, ty:-100, tz:225, zoom: document.body.offsetHeight?(document.body.offsetHeight/2400):0.15 }

}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    backgroundColor: 'white',
    display: 'flex',
    '& > *': {
      padding: theme.spacing(1),
    },
  },
  button: {
    padding: 0,
    width: '2rem',
    minWidth: '2rem',
    height: '2rem',
    minHeight: '2rem'
  }
}));

function SceneAsset({ buildingColor, parkColor, environment, setEnvironment, alpha, setAlpha }) {
  const alphaMap = useLoader(THREE.TextureLoader, gradientPath)
  const gltf = useLoader(GLTFLoader, scenePath).scene.children[0];
  useLayoutEffect(()=>{
    if(!alpha) setAlpha(alphaMap);
    if(alpha&&gltf&&!environment){
      //console.log(gltf)
      const edited = gltf
      //delete edited.children[1].children[1]
      edited.children[0].material = new THREE.MeshStandardMaterial({
        color: buildingColor, transparent: true, alphaMap: alpha, opacity: 0.6,
      });
      setEnvironment(edited)// #a6c9ac  #7fd890
    }  
  },[alpha, gltf, environment, alphaMap, buildingColor, setAlpha, setEnvironment])
  return environment? <primitive object={environment} dispose={null} />: null
}

const Fallback = () => (
  <HTML center>
    <Typography variant="button">
      Loading...
    </Typography>
    {/* <div className="loading">Loading...</div> */}
  </HTML>
)

const Startup = () => {
  const canvas = document.getElementsByTagName('canvas')[0];
  const [op, setOp] = useSpring(() => ({ 
    opacity: 0,
    config: { duration: 4000, easing: easings.easeQuadInOut }
  }))
  setOp({opacity: 1}) 
  useFrame(() => {
    if(op.opacity.value!==1) canvas.style.opacity = op.opacity.value
  })
  return null
}

const Navigate = ({ controls, dollyFinished, setDollyFinished, park, setPark, nextZoom, setNextZoom, zoom, setZoom }) => {
  const { camera } = useThree()
 
  useEffect(()=>{
    setDollyFinished(false)
  }, [ park, setDollyFinished ] )

  // useEffect(()=>{
  //   console.log(camera.position, camera.zoom, controls.current.target)
  // },[dollyFinished])

  const p = 0.0001
  const t = 0.05
  useFrame(() => {
    if(!dollyFinished&&!nextZoom){
        {
          const {x,y,z} = camera.position?camera.position:{x:920, y:460, z:260}
          camera.position.set(
            lerp(x, park?l[park].x:l.default.x, t),
            lerp(y, park?l[park].y:l.default.y, t),
            lerp(z, park?l[park].z:l.default.z, t)
          );
        }
        camera.zoom = lerp(camera.zoom?camera.zoom:0.18, park?l[park].zoom:l.default.zoom, t);
        {
          const {x,y,z} = controls.current?controls.current.target:{x: 590, y: -100, z: 240}
          if (Math.abs(x-(park?l[park].tx:l.default.tx))<p) setDollyFinished( true )
          controls.current.target = new THREE.Vector3(
            lerp(x, park?l[park].tx:l.default.tx, t),
            lerp(y, park?l[park].ty:l.default.ty, t),
            lerp(z, park?l[park].tz:l.default.tz, t)
          );
        }
        controls.current.update()
        camera.updateProjectionMatrix();   
    }else if(!dollyFinished&&nextZoom){
        camera.zoom = lerp(camera.zoom?camera.zoom:0.18, nextZoom, t);
        if (Math.abs(camera.zoom-nextZoom)<0.05){
          setDollyFinished( true )
          setNextZoom( null )
          if(camera.zoom<0.5) {setPark(null); setDollyFinished(true)}
        }
        controls.current.update()
        camera.updateProjectionMatrix();   
    }
    setZoom(camera.zoom)
    })

  return null
}

export default (props) => {
  const controls = useRef()
  const [environment, setEnvironment] = useState(null)
  const [dollyFinished, setDollyFinished] = useRecoilState(Atoms.dollyAtom)
  const setPark = useSetRecoilState(Atoms.parkAtom)
  const [alpha, setAlpha] = useState(null)
  const classes = useStyles()
  const [zoom, setZoom] = useState(0.18)
  const [nextZoom, setNextZoom] = useState(null)

  useLayoutEffect(()=>{
    if(props.dom) {
      const canvas=props.dom.getElementsByTagName('canvas')[0]
      canvas.addEventListener('pointerdown', (e)=>{
        setDollyFinished(true)}, {passive: true});
      canvas.addEventListener('wheel', ()=>{console.log('wheel'); setDollyFinished(true)}, {passive: true});
    }
  }, [props.dom, setDollyFinished])

  return (<>
    <ButtonGroup
      className={classes.root}
      style={{zIndex:10, backgroundColor:'white'}}
      orientation="vertical"
      color="primary"
      aria-label="vertical outlined primary button group"
    >
      <Button size="small" className={classes.button} onClick={()=>{
        setDollyFinished(true)
        setNextZoom(zoom*2)
        setDollyFinished(false)
        console.log(zoom*2)

      }}>+</Button>
      <Button size="small" className={classes.button} onClick={()=>{
        setDollyFinished(true)
        setNextZoom(zoom*0.5)
        setDollyFinished(false)
        console.log(zoom*0.5)
      }}>-</Button>
      <Button size="small" className={classes.button} onClick={()=>{
        props.setKind(null)
        setPark(null)
        setNextZoom(null)
        setDollyFinished(false)
      }}>
        <Home fontSize='small' />
      </Button>
    </ButtonGroup>
    <Canvas 
      onClick = {(event)=>{
        if (event.originalEvent !== undefined && event.cancelable) { 
        //if (event.preventDefault){
        event.preventDefault(); 
        }
        setDollyFinished(true)}}
      onDoubleClick = {(event)=>{
        if (event.originalEvent !== undefined && event.cancelable) { 
        //if (event.preventDefault){
        event.preventDefault(); 
        }
        setDollyFinished(true)}}
      onWheel = {(event)=>{
        if (event.originalEvent !== undefined && event.cancelable) { 
        //if (event.preventDefault){
        event.preventDefault(); 
        }
        setDollyFinished(true)}}
      onPointerDown = {(event)=>{
        if (event.originalEvent !== undefined && event.cancelable) { 
        //if (event.preventDefault){
        event.preventDefault(); 
        }
        setDollyFinished(true)}}
      onPointerMissed={(event) => {
        if (event.originalEvent !== undefined && event.cancelable) { 
        //if (event.preventDefault){
        event.preventDefault(); 
        }
        console.log('missed')
        props.setKind(null)
      }}
      orthographic = {true}
      resize={{scroll: false}}
      pixelRatio={window.devicePixelRatio}
      camera={{ 
        position: [920, 460, 260], 
        zoom: 0.18,
        near: -1000,
        far: 10000
      }}
      concurrent
    >
      <OrbitControls ref={controls} maxPolarAngle={ Math.PI/6 } minPolarAngle={ Math.PI/8 } />
      <ambientLight intensity={0.3} />
      <directionalLight position={[10, 10, 10]} color={0xFFFFFF} intensity={0.9} />
      <pointLight position={[100, 100, 100]} color={0xFFFFFF} intensity={0.5} />
      
      <Suspense fallback={ <Fallback /> }>
        <Startup />
        <group>
          <SceneAsset 
            buildingColor={'rgb(240,240,240)'} 
            parkColor={'#e1f6e2'}
            environment={environment}
            setEnvironment={setEnvironment}
            alpha={alpha}
            setAlpha={setAlpha} 
          />
          <Flowers 
            {...props}
          />
          <Lines />
          <Trees />
        </group>
        <Navigate 
          controls={controls}
          dollyFinished={dollyFinished}
          setDollyFinished={setDollyFinished}
          nextZoom={nextZoom}
          setNextZoom={setNextZoom}
          zoom={zoom}
          setZoom={setZoom}
         {...props} />
      </Suspense> 
    </Canvas>
  </>)
}