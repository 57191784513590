import { createMuiTheme } from '@material-ui/core/styles'
import { grey, amber, green } from '@material-ui/core/colors'

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: grey[500],
      main: grey[600],
      dark: grey[900],
      contrastText: '#fff',
    },
    secondary: {
      light: green[400],
      main: green[500],
      dark: green[600],
      contrastText: '#000',
    },
    accent: {
      ...amber,
    },
  },
  overrides: {
    MuiAppBar: {
      height: 100,
    },

  },
})
