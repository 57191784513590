import * as THREE from 'three';

export default (segments) => {
  const path = new THREE.Path();
  segments.forEach((segment, i) => {
    if (i === 0) {
      path.moveTo(...segment[0]);
    }
    if (segment.length === 2) {
      path.lineTo(...segment[1]);
    } else if (segment.length === 3) {
      path.quadraticCurveTo(...segment[1], ...segment[2]);
    } else if (segment.length === 4) {
      path.bezierCurveTo(...segment[1], ...segment[2], ...segment[3]);
    } else {
      const points = [];
      segment.forEach((s) => {
        const point = new THREE.Vector2(...s);
        points.push(point);
      });
      path.splineThru(points);
    }
  });
  return path;
}
