import React, { useRef, useState, useEffect, useMemo, useLayoutEffect } from 'react'
import * as THREE from 'three';
import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { useLoader, useThree, useFrame } from 'react-three-fiber'
import lerp from "lerp"
import flowerPath from '../../../assets/flower-static.glb'


function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const height = 20;
const dummy = new THREE.Object3D()
const tempColor = new THREE.Color()

let en = {}
export default ({ week, filtered, flowers, kind, setKind }) => {

  const duration = 2
  const rotation = Math.PI/2
  const bud = useRef()
  const stem = useRef()
  const boundary = useRef()
  const flower = useLoader(GLTFLoader, flowerPath)
  const minNumber = useMemo(()=>{ return flowers.length }, [flowers])
  const prevFiltered = usePrevious(filtered)

  const [colors, setColors] = useState()
  const [ geometry, setGeometry ] = useState(null)
  const [visible, setVisible] = useState([])
  const [hovered, set] = useState()

  useLayoutEffect(()=>{
    const geometry = BufferGeometryUtils
    .mergeBufferGeometries([
      flower.scene.children[2].geometry.scale(100, 100, 100),
      flower.scene.children[2].geometry.clone().rotateY((2*Math.PI)/5),
      flower.scene.children[2].geometry.clone().rotateY((2*2*Math.PI)/5),
      flower.scene.children[2].geometry.clone().rotateY((2*3*Math.PI)/5),
      flower.scene.children[2].geometry.clone().rotateY((2*4*Math.PI)/5),

      new THREE.SphereBufferGeometry(0.6, 10, 10, 0, Math.PI*2, 0, Math.PI*2)
    ]).translate(0, height, 0)

    setGeometry(geometry)
    //const budGeom = BufferGeometryUtils.mergeBufferGeometries([bud.current.geometry.clone(), bud.current.geometry.clone().scale(100, 100, 100)])

    bud.current.geometry = geometry  
  }, [flower])

  const { clock } = useThree()

  useEffect(()=>{
    const staying = prevFiltered?filtered.filter(x => prevFiltered.includes(x)):[]
    const entering = (prevFiltered?filtered.filter(x => !prevFiltered.includes(x)):filtered).map(v=>Object.assign(v, { enter: clock.elapsedTime, lerp: lerp(v.lerp?v.lerp:0, duration, 0.1) }))
    const exiting = prevFiltered? prevFiltered.filter(x => !filtered.includes(x)).map(v=>Object.assign(v, { exit: clock.elapsedTime, lerp: lerp(v.lerp?v.lerp:0, 0, 0.1) })):[]
    const allVisible = entering.concat(staying, exiting)
    const allInvisible = flowers? flowers.filter(x=>!allVisible.includes(x)).map(v=>{ return v }):[]
    const all = allInvisible.concat(allVisible).sort((a,b)=>a.FID-b.FID)
    const colorArray = all.map( f=>(String(f.Color)==="0"||f.Color==="White"||!f.Color)?"white":f.Color.split('/')[0] )
    const colorArray32 = Float32Array.from(new Array(colorArray.length).fill().flatMap((_, i) => tempColor.set(colorArray[i]).toArray()))
    setColors(colorArray32)
    setVisible(all)
    
   }, [filtered, week, flowers, prevFiltered])

  useEffect(() => {
    const id = kind?visible.findIndex((v)=>v["Plant Name"]===kind["Plant Name"]&&String(v["Bed"])===String(kind["Bed"])):null
    //animateSelected = 1
    //animateDeselected = 1.5
    set(id)
  }, [kind, visible])
  
  useFrame(() => {
    visible.forEach((e,i)=>{ 
      
      const t = 0.02
      const p = 0.001
      let delta

        if(e.enter-e.exit>0){
       
          //it is visible
          if(!en[i]) {en[i] = lerp(0, duration+p, t)}
          else{ 
            if(en[i]<duration){
              en[i] = lerp(en[i], duration+p, t)
            }
            delta = en[i]>duration?duration:en[i]
          }
        }else{

            if(!en[i]) {en[i] = lerp(0, -p, t)}
            else{
              if(en[i]>0){
                en[i] = lerp(en[i], -p, t)
              }
              delta = en[i]<0?0:en[i]
            }
        }
        const c = delta/duration //easings.easeQuadInOut(delta/duration)
    
        //console.log(bud)
        bud.current.instanceMatrix.needsUpdate = true
        stem.current.instanceMatrix.needsUpdate = true
        boundary.current.instanceMatrix.needsUpdate = true

        const {x, y, z} = e.location
        const scale  = i === hovered ? 1.3*e.scale : e.scale

        const angle = e.angle
        dummy.position.set(x, y, z)
        dummy.rotation.set(0, angle*(rotation*c), 0)
        dummy.scale.set(scale*scale*0.8*c, scale*scale*c, scale*scale*0.8*c)
  
        dummy.updateMatrix()
        bud.current.setMatrixAt(i, dummy.matrix)
        stem.current.setMatrixAt(i, dummy.matrix)
        boundary.current.setMatrixAt(i, dummy.matrix)

    })
    if(geometry&&colors) bud.current.geometry.setAttribute("color", new THREE.InstancedBufferAttribute(colors, 3, false, 1));

   })

  const stemRadius = 0.25;
  const stemBoundaryRadius = 5;

  const subdiv = 30;
  
  return (
    <group>
      <instancedMesh 
        ref={stem} args={[null, null, minNumber]} renderOrder={0} frustumCulled={false} >
        <meshStandardMaterial attach="material" transparent opacity={1} color="rgb(110, 186, 130)" />
        <tubeBufferGeometry name="stem" attach="geometry" args={[new THREE.LineCurve3(new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, height, 0)), 2, stemRadius, subdiv, false ]} />
      </instancedMesh>
      <instancedMesh 
        onPointerUp={(event)=>{
          //if (event.originalEvent !== undefined && event.cancelable) {
          if (event.preventDefault){ 
          event.preventDefault(); 
          }
          //console.log('pointer up')
          set(event.instanceId); setKind(visible[event.instanceId])
        }}
        onTouch={(event)=>{
          //if (event.originalEvent !== undefined && event.cancelable) { 
          if (event.preventDefault){
          event.preventDefault(); 
          }
          set(event.instanceId); setKind(visible[event.instanceId])
        }}
        onTap={(event)=>{
          //if (event.originalEvent !== undefined && event.cancelable) { 
          if (event.preventDefault){
          event.preventDefault(); 
          }
          set(event.instanceId); setKind(visible[event.instanceId])
        }}
        onClick={(event) => {
          //if (event.originalEvent !== undefined && event.cancelable) { 
          if (event.preventDefault){
          event.preventDefault(); 
          }
          set(event.instanceId); setKind(visible[event.instanceId])
        }}
        onPointerDown={(event) => {
          //if (event.originalEvent !== undefined && event.cancelable) { 
          if (event.preventDefault){
          event.preventDefault(); 
          }
          set(event.instanceId); setKind(visible[event.instanceId])
        }}
        onDoubleClick={(event) => {
          //if (event.originalEvent !== undefined && event.cancelable) { 
          if (event.preventDefault){
          event.preventDefault(); 
          }
          set(event.instanceId); setKind(visible[event.instanceId])
        }}
        onContextMenu={(event) => {
          //if (event.originalEvent !== undefined && event.cancelable) {
          if (event.preventDefault){ 
          event.preventDefault(); 
          }
          set(event.instanceId); setKind(visible[event.instanceId])
        }}

        ref={boundary} args={[null, null, minNumber]} renderOrder={0} frustumCulled={false} >
        <meshBasicMaterial colorWrite={false} attach="material" transparent opacity={0} color="rgb(110, 186, 130)" side={THREE.BackSide} />
        <tubeBufferGeometry name="stemBoundary" attach="geometry" args={[new THREE.LineCurve3(new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, height, 0)), 2, stemBoundaryRadius, subdiv, false ]} />
      </instancedMesh>
      <instancedMesh 
        ref={bud} args={[null, null, minNumber]} renderOrder={1} frustumCulled={false} >       
        <meshPhongMaterial attach="material" transparent opacity={0.9} vertexColors={THREE.VertexColors} />
      </instancedMesh>
    </group>
  )
}