import React, { useRef, useEffect, useState, useLayoutEffect } from 'react'
import * as THREE from 'three';
import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils'
import { useLoader } from 'react-three-fiber'

import { trees } from '../../../assets/trees.json'
import treeTexturePath from '../../../assets/noise1.jpg';
//let palette = ["#115f5c", "#1a936f", "#8Bd498", "#c6dabf", "#f3e9d2"]
let palette = ['#e1f6e1', '#e1f6e2', '#e1f6e3', '#e1f6e4', '#e1f6e5']
//let palette = ['#ffc453', '#ffb151', '#ffa159', '#ffb151', '#ffa159']

const Trees = () => {

  const [ colors ] = useState(new Array(trees.length).fill().map(() => palette[Math.floor(Math.random() * 5)]))
  //console.log(niceColors[3])
  const tempColor = new THREE.Color()
  const colorArray = Float32Array.from(new Array(trees.length).fill().flatMap((_, i) => tempColor.set(colors[i]).toArray()))


  const amount = trees.length
  const bark = useRef()
  const crone = useRef()
  const dummy = new THREE.Object3D()
  const coords = trees.map((t) => [t[0], 0, t[1]])
  const treeMap = useLoader(THREE.TextureLoader, treeTexturePath)


  useLayoutEffect(state => {
    coords.forEach(([x, y, z], i) => {
      //add Rotation
      const coefficient = 1+(Math.random()*0.4)//1.3+(Math.random()*0.4)
      const angle = Math.PI*Math.random()*0.25//1.3+(Math.random()*0.4)

      dummy.updateMatrix(void dummy.position.set(x, y, z))
      dummy.updateMatrix(void dummy.rotation.set(0, angle, 0))
      dummy.updateMatrix(void dummy.scale.set(coefficient, coefficient+(0.4*(Math.random()-0.5)), coefficient+(0.4*(Math.random()-0.5))))
      bark.current.setMatrixAt(i, dummy.matrix)
      crone.current.setMatrixAt(i, dummy.matrix)
    })
    bark.current.instanceMatrix.needsUpdate = false
    crone.current.instanceMatrix.needsUpdate = false

    //crone.current.geometry = new THREE.BoxBufferGeometry(radius*1.4, height, radius*1.4).translate(0, tHeight, 0);
    crone.current.geometry = BufferGeometryUtils
      .mergeBufferGeometries([
        new THREE.TubeBufferGeometry(
          new THREE.LineCurve3(
            new THREE.Vector3(0,height,0), 
            new THREE.Vector3(0, tHeight, 0)
          ), 2, radius, subdiv, false),
        new THREE.SphereBufferGeometry(radius, subdiv, 10, 0, Math.PI*2, 0, Math.PI*0.5)
          .translate(0, tHeight, 0),
        new THREE.SphereBufferGeometry(radius, subdiv, 10, 0, Math.PI*2, Math.PI * 0.50, Math.PI*0.5)
          .translate(0, height, 0)
      ]);
      crone.current.geometry.setAttribute("color", new THREE.InstancedBufferAttribute(colorArray, 3, false, 1));
  })

  const barkRadius = 0.25;
  const radius = 1.8;
  const subdiv = 30;
  const height = 6;
  const tHeight = 8;
  
  return (
    <group>
      <instancedMesh ref={bark} args={[null, null, amount]} renderOrder={2} frustumCulled={false} >
        <tubeBufferGeometry name="bark" attach="geometry" args={[new THREE.LineCurve3(new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, height, 0)), 2, barkRadius, subdiv, false ]} />
        <meshStandardMaterial attach="material" transparent opacity={1} color="#9e9492" />
      </instancedMesh>
      <instancedMesh ref={crone} args={[null, null, amount]} renderOrder={3} frustumCulled={false} >       
        <meshPhongMaterial attach="material" transparent opacity={0.8} bumpScale={0.2} emissive="#111111"  vertexColors={THREE.VertexColors} >
          {/* <texture attach="map" image={treeMap.image} onUpdate={self => treeMap.image && (self.needsUpdate = true)} /> */}
          <texture attach="bumpMap" image={treeMap.image} onUpdate={self => treeMap.image && (self.needsUpdate = true)} />
        </meshPhongMaterial>
        {/* <meshStandardMaterial attach="material" transparent opacity={0.7} color="#7fd890" /> */}
      </instancedMesh>
    </group>
  )
}

export default Trees