import React from 'react';
import { ListItem, ListItemText } from '@material-ui/core';
import {ReactComponent as FlowerIcon} from 'assets/ICON_SVG/FLOWER.svg'
import { fixColor } from 'global/misc'

export default ({ index, style, filtered, setKind }) => {
  if(filtered){
    return filtered && (
      <ListItem button 
        style={ {maxHeight: 54} } 
        key={index} 
        disableGutters={true} 
        // ContainerProps={{ style }} 
        //ContainerComponent="div"
        onClick={
          ()=>{
            setKind(filtered[index])
          }
        } 
      >
        <FlowerIcon stroke={(filtered[index]["Color"]==="#ffffff"||filtered[index]["ColorName"]==="White")?"grey":undefined} fill={(filtered[index]["Color"]==="#ffffff"||filtered[index]["ColorName"]==="White")?"#ffffff":fixColor(filtered[index]["Color"])} style={{marginLeft: '1rem', transform: "scale(1.3)"}}/>
        <ListItemText style={{paddingLeft:'1.5rem', color: 'black'}} 
          primary={
            filtered[index]["Common Name"]!=='0'?
              filtered[index]["Common Name"].substring(0, 25):
              filtered[index]["Plant Name"].substring(0, 25)}
          secondary={filtered[index]["Plant Name"].substring(0, 25).concat("...")}  
        />
        {/* <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="location">
            <LocationOnOutlined />
          </IconButton>
        </ListItemSecondaryAction> */}
      </ListItem>
    );
  }else{
    return null
  }
}