import React, { useEffect, useState, useLayoutEffect } from 'react'
import Draggable from 'react-draggable'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import * as Atoms from 'global/Atoms'
import { makeStyles, useTheme, Button, Box, Paper, List, Typography, Hidden, useMediaQuery, ListItem, ListItemText } from '@material-ui/core'
import { DragHandleRounded, VisibilityOutlined, LocationOnOutlined  } from '@material-ui/icons'
import moment from 'moment/moment'
import ParkRow from './park'
import FlowerRow from './flower'
import {ReactComponent as FlowerIcon} from 'assets/ICON_SVG/FLOWER.svg'
import { fixColor } from 'global/misc'
import { streets } from 'global/streets'


const useStyles = makeStyles(theme => ({

    underlay: {
      padding: 0, 
      borderRadius:4,
      color: theme.palette.text.secondary,
      paddingBottom: 4,
      width: '100%',
      overflow: 'hidden',
      // color: 'black',
      
      [theme.breakpoints.up('sm')]: {
        borderRadius:0,
        height: '100vh',
      },
    },
    paper: {
      width:"100%",
      //height: "100%",
      position: "relative",
      // padding: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      marginTop:0, 
      padding: 0,
      paddingBottom:20, 
      borderRadius: 0,  
    },
    header: {
      //marginTop: -10,
      width: "100%",
      //height: '104px',
    },
    icons: {
      alignItems:"center",
      justifyContent:"center",
      display:"flex",
      textAlign: "center"
    },
}));

const Dashboard = ( { filtered, beds, parks, park, setPark, kind, setKind } )=> {
  
  const classes = useStyles()
  const theme = useTheme()
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));
  const [headingRef, headingSize] = useMeasure({ polyfill: ResizeObserver })

  const [position, setPosition] = useState({x:0, y:0});
  const [height, setHeight] = useState(window.innerHeight);
  const dateBlockSize = useRecoilValue(Atoms.dateBlockAtom)
  const setHeadingSize = useSetRecoilState(Atoms.headingSize)

  useEffect(()=>{
    if(headingSize.height&&dateBlockSize.height){
      setHeadingSize(headingSize)
    }

  }, [headingSize, setHeadingSize, dateBlockSize, kind])

  useLayoutEffect(()=>{
    window.addEventListener("resize", ()=>{
      setHeight(window.innerHeight);
    })
  }, [filtered])

  return (
    
    <Draggable
      defaultClassName={classes.draggable}
      axis="y"
      handle=".handle"
      disabled={desktop}
      bounds={{top:-height+headingSize.height+dateBlockSize.height, bottom: kind? dateBlockSize.height:0}}
      position={!desktop?position:{x:0, y:0}}
      scale={1}
      cancel=".MuiButtonBase-root, #DateBlock"
      onMouseDown={()=>{setPosition(undefined)}}
    >
      <Box id="dashboard"  className={'handle'} style={{flexGrow: 1}} >
        <Paper className={classes.underlay}>
          
        <Box ref={headingRef} id="Heading" textAlign="center" className={classes.header} style={{boxSizing: "border-box"}}>
          <Paper className={classes.paper} style={{ paddingTop: desktop? '2rem':'0'}}>
            <Hidden smUp> <DragHandleRounded style={{color: 'lightgrey'}}/></Hidden>
            <Typography variant="h5" color="textPrimary" style={{fontSize:"1.3rem"}} > 
              {!kind? 
                (<>{filtered? filtered.length: '' } species are typically in bloom</>) :
                (<>
                  <FlowerIcon stroke={(kind["Color"]==="#ffffff"||kind["ColorName"]==="White")?"grey":undefined} fill={kind["Color"]==="#ffffff"?"#ffffff":fixColor(kind["Color"])} style={{marginLeft: '1rem', transform: "scale(1.3)", marginRight:'0.5rem'}}/>
                  {(kind["Common Name"]&&Number(kind["Common Name"])!==0)?kind["Common Name"]:kind["Plant Name"]}
                </>)}
            </Typography>
            { (park || kind) && (
                <Typography variant="subtitle1">
                {!kind? (<>Near the {park}</>) : kind["Plant Name"]}
                </Typography>
            )}
            <Hidden smUp>
            { !park && (<Typography variant="caption"> 
                Swipe up for more information
              </Typography>
            )}
            </Hidden>
          </Paper>
        </Box>
        <div 
          className={'no-scrollbars'} 
          style={{ 
            width: '100%',
            height: kind?'100vh':`calc( 100vh - ${dateBlockSize.height+headingSize.height}px )`,
            overflowY: 'scroll'
          }
        }>
          {!kind? (park? filtered && (
            <List 
              className={'no-scrollbars'}  
              height="100%" 
              style={{ marginBottom: dateBlockSize.height,  width: '100%', height: "100%" }} 
            >
              {filtered.map((f,i)=><FlowerRow key={i} index={i} setPark={setPark} setKind={setKind} park={park} parks={parks} filtered={filtered}/>)}
            </List> 
          ): beds && (
            <List 
              className={'no-scrollbars'}  
              style={{ 
                width: '100%',
                height: "100%",
                marginBottom: dateBlockSize.height,
                overflowY: 'scroll'
              }}
            >
                {parks.map((p,i)=><ParkRow key={i} index={i} parks={parks} setPark={setPark} park={park} filtered={filtered}/>)}
            </List> 
          )):(<>
            <List style={{height: "100%"}}>
                <ListItem key={0} color='inherit' style={{paddingLeft:"1.5rem", color: 'black'}}>
                  <ListItemText 
                    primary={"Near "+ kind.park}
                    secondary={streets[kind.park]}
                  />
                </ListItem>
                <ListItem key={2} style={{paddingLeft:"1.5rem", color: 'black'}}>
                  <ListItemText
                    primary={
                      moment().day("Monday").week(Number(kind.weeks[0])).format('MMMM DD') +
                      " - " +
                      moment().day("Sunday").week(Number(kind.weeks[kind.weeks.length-1])).format('MMMM DD')
                    }
                  />
                </ListItem>
                <ListItem key={3} style={{paddingLeft:"1.5rem", color: 'black'}}>
                  <ListItemText
                    primary={
                      "Bloom colors: " + kind.ColorName
                    }
                  />
                </ListItem>
                <Button
                  avatar={<VisibilityOutlined fontSize="small" />}
                  variant="contained"
                  label="How it looks" 
                  component="a" 
                  href={`https://www.google.com/search?q=${kind["Plant Name"]}&tbm=isch`}
                  aria-label="appearance"
                  target="_blank"
                  style={{marginLeft:'1rem', height: '2rem', borderRadius: '1rem', marginTop: '1rem'}}
                ><VisibilityOutlined fontSize="small" />How it looks </Button>
                <Button
                  avatar={<LocationOnOutlined fontSize="small"/>}
                  variant="contained"
                  label="How to get there" 
                  component="a" 
                  aria-label="location"
                  target="_blank"
                  href={`https://www.google.com/maps/dir/?api=1&destination=${kind["bedLocation"].sort((a,b)=>b-a).join(",")}`}
                  style={{marginLeft:'1rem', height: '2rem', borderRadius: '1rem', marginTop: '1rem'}}
                ><LocationOnOutlined fontSize="small"/>How to get there </Button>
            </List>
          </>)
          }
        </div>
        </Paper>
      </Box>
    </Draggable>
  );
}
export default Dashboard

// Link to google photos search https://www.google.com/search?q=romashka&tbm=isch
// ++ Google Custom Search JSON API
// Link to google maps pin https://www.google.com/maps/search/?api=1&query=47.5951518,-122.3316393
