import { atom } from 'recoil'

export const modeAtom = atom({
  key: 'mode',
  default: 'home'
})

export const dollyAtom = atom({
  key: 'dolly',
  default: false
})

export const dateAtom = atom({
  key: 'date',
  default: new Date()
})
  
export const flowersAtom = atom({
  key: 'flowers',
  default: null
})

export const parkAtom = atom({
  key: 'park',
  default: null
})

export const kindAtom = atom({
  key: 'kind',
  default: null
})

export const dateBlockAtom = atom({
  key: 'dateBlock',
  default: 180
})

export const handleAtom = atom({
  key: 'handle',
  default: 180
})

export const headingSize = atom({
  key: 'headingSize',
  default: {}
})