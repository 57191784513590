import React from 'react';
import { ListItem, ListItemText } from '@material-ui/core';
// import {ReactComponent as FlowerIcon} from 'assets/ICON_SVG/FLOWER.svg'
// import { fixColor, sortColor } from 'global/misc'
import { streets } from 'global/streets'

export default ({ index, parks, setPark, park, filtered })=> {
  //console.log(park, streets)
  if(parks){
    return (
      <ListItem button 
        onClick={
          ()=>{
            setPark(parks[index])
          }
        } 
        style={ {color: 'black', position:'relative', paddingTop:0, paddingBottom:0} } 
        key={index} 
        disableGutters={true} 
        //ContainerProps={{ style }} 
        ContainerComponent="div"
      >
        <ListItemText 
          primary={
            (filtered?
              filtered.filter(f=>f.park===parks[index]).length:'')+
            ' near '+parks[index]
          }
          secondary={ streets[parks[index]] }
          // secondary={
          //   <>
          //     {
          //       filtered&&filtered.filter(f=>f.park===parks[index]).length!=0?
          //         filtered.filter(f=>f.park===parks[index]).sort(sortColor).map((f,i)=><FlowerIcon key={i} stroke={f["Color"]==="#ffffff"?"grey":undefined} fill={f["Color"]==="#ffffff"?"#ffffff":fixColor(f["Color"])} />):
          //         <FlowerIcon fill={'rgb(0,0,0)'} opacity={0.1} />
          //     }
          //   </>
          // }
          style={{marginLeft:"auto", display:"block", position:"relative", float:"left", textAlign:"left", padding:"0", paddingRight:"2rem", paddingLeft:"1.5rem"}}
        />
        
        
      </ListItem>
    );
  }
}
