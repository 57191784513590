import React, { useMemo, useState, useLayoutEffect, useRef } from 'react'
import { Box, Fab, makeStyles, useMediaQuery } from '@material-ui/core'
import { Undo } from '@material-ui/icons'
import * as moment from "moment/moment";
import { ThemeProvider } from '@material-ui/core/styles'
import { RecoilRoot, useRecoilState, useRecoilValue } from 'recoil'
import * as Atoms from 'global/Atoms'
import Dashboard from './dashboard'
import DateBlock from './dateblock'
import Slider from './slider'
import Scene from './scene'
import { theme } from 'global/Theme'
//import Populate from './scene/populatePoints'
//import { csv } from 'd3-fetch'
//import data from '../assets/data.csv'

import data from '../assets/flowers.json'
import { beds } from '../assets/beds.json'

const parks = ["North End", "Wharf District", "Dewey Square", "Chinatown"]//Array.from(groupBy(Object.keys(beds), b=>b.split("|")[1]).keys())

const useStyles = makeStyles((theme) => ({
  root:{
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  map: {
    top: 0,
    width: '100%',
    position: 'fixed',
		
		[theme.breakpoints.up('sm')]: {
			height: '100%',
			minWidth: '50%',
			width: `calc( 100vw - 350px )`,
			justify: "flex-start",
			float: "left",
		},
	},
	dashboard: {
    position:'relative',
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginTop: 0,
			width: '50%',
			maxWidth: 350,
			justify: "flex-end",
			float: "right",
		},
  },
  fab: {
    transitionDuration: '1s',
    transitionTimingFunction: 'ease-in-out',
  }
}));

const App = ()=>{
  const classes = useStyles()
  const [date, setDate] = useState(new Date())
  
  const [loaded, setLoaded] = useState(false)
  const [flowers, setFlowers] = useState(null)

	const [park, setPark] = useRecoilState(Atoms.parkAtom)
  const [kind, setKind] = useState(null)
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));
  const ref = useRef()
  const headingSize = useRecoilValue(Atoms.headingSize)
  const dateBlockSize = useRecoilValue(Atoms.dateBlockAtom)

  useLayoutEffect(()=>{
    if (!loaded){
      setLoaded(true)
      setFlowers(data)
      // csv(data).then(d=>{
      //     setFlowers(Populate(d, beds));
      // })
    }
  }, [loaded])

  const memoFilter = useMemo(()=>{
    return flowers?flowers.filter(b=>{
      return park? b.park===park&&b.weeks.includes(String(moment(date).isoWeek()))
        :b.weeks.includes(String(moment(date).isoWeek()))
    }):null;
  }, [date, park, flowers])

  return (
    <Box id="app" className={classes.root}>
    <Box style={{height: desktop? '100vh':`calc( 100vh - ${headingSize.height}px )`}} className={classes.map} ref={ref}>
        <Scene 
          flowers={flowers}
          filtered={memoFilter}
          beds={beds}
          week={String(moment(date).isoWeek())}
          parks={parks}
          park={park}
          setPark={setPark}
          kind={kind}
          setKind={setKind}
          dom = {ref.current}
        />
        { desktop && (
          <Slider
            date={date}
            setDate={setDate}
          />
          )
        }
    </Box>
    <Box style={{marginTop: desktop?0:`calc( 100vh - ${dateBlockSize.height+headingSize.height}px )`}} className={classes.dashboard}>

      { (park || kind) && (
        <Fab
          aria-label="return"
          className={classes.fab}
          style={{
            position: "fixed", 
            zIndex: 7,
            padding: 0, 
            right: "1rem", 
            bottom: kind?"1rem":"3rem"
          }}
          onClick={()=>{
            if(kind) {setKind(null)}
            else {setPark(null)}
          }}
        >
          <Undo fontSize="small" />
        </Fab>
      )}
      <DateBlock
        getDateProp={date}
        setDateProp={setDate}
        kind={kind}
      />
      <Dashboard
        park={park}
        date={date}
        setPark={setPark}
        filtered={memoFilter}
        beds={beds}
        parks={parks}
        kind={kind}
        setKind={setKind}
      />
    </Box>
  </Box>
  )
}

export default () => {
	return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </RecoilRoot>
	);
}
